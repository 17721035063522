<template>
    <!--主体代码-->
   <div id="zhl_12">
       <div id="box">
           <div class="nr di clearfix">
               <div class="duibi clearfix">
                   <span>沪深北交易所上市条件对比</span>
               </div>
			
			<table class="table">
				<tr>
					<th colspan="2">类型</th>
					<th>IPO条件</th>
					<th>主板</th>
					<th>科创板</th>
					<th>创业板</th>
					<th>北交所</th>
				</tr>
				<tr>
					<td colspan="2" class="text-bold">非红筹、无差异表决权的一般企业</td>
					<td>预计市值、收入、净利润、现金流、研发投入等组合指标</td>
					<td>标准一：最近3年净利润均为正，且最近3年净利润累计不低于2亿元，最近1年净利润不低于1亿元，最近3年经营活动产生的现金流量净 <br> 额累计不低于2亿元或者营业收入累计不低于15亿元标准二：预计市值不低于50亿元，且最近1年净利润为正，最近1年营业收入不低于6亿元，最近3年经营活动产生的现金流量净额累计不低于2.5亿元<br>标准三：预计市值不低于100亿元，且最近1年净利润为正，最近1年营业收入不低于10亿元</td>
					<td>标准一：预计市值≥10亿元，最近2年盈利且累计不低于5,000万元，或最近1年盈利且营业收入不低于1亿元<br>标准二：预计市值≥15亿元，最近1年营业收入不低于2亿元，且近3年研发投入合计占近3年收入比例不低于15%<br>标准三：预计市值≥20亿元，最近1年收入不低于3亿元，且最近3年经营活动现金流量净额累计不低于1亿元<br>标准四：预计市值≥30亿元，最近1年收入不低于3亿元<br>标准五：预计市值≥40亿元，主要业务或产品需经国家有关部门批准，市场空间大，已取得阶段性成果，并获得知名投资机构一定金额投资。医药行业企业取得至少1项一类新药二期临床试验批件，其他符合科创板定位的企业需具备明显的技术优势并满足相应条件。</td>
					<td>标准一：最近2年净利润均为正，且累计净利润不低于1亿元，最近1年净利润不低于6000万元<br>标准二：预计市值不低于15亿元，最近1年净利润为正且营业收入不低于4亿元<br>标准三：预计市值不低于50亿元且最近1年营业收入不低于3亿元</td>
					<td>标准一：市值不低于2亿元，最近两年净利润均不低于1,500万元且加权平均净资产收益率平均不低于8%或者最近一年净利润不低于2,500万元且加权平均净资产收益率不低于8%<br>标准二：市值不低于4亿元，最近两年营业收入平均不低于1亿元且最近一年增长率不低于30%最近一年经营活动产生的现金流量净额为正<br>标准三：市值不低于8亿元，最近一年营业收入不低于2亿元，最近两年研发投入合计占最近两年营业收入合计比例不低于8%<br>标准四：市值不低于15亿元，最近两年研发投入合计不低于5,000万元</td>
				</tr>
				<tr>
					<td colspan="2" rowspan="2" class="text-bold">已在境外上市红筹</td>
					<td>市值</td>
					<td colspan="3">市值不低于2,000亿元</td>
					<td>暂不适用</td>
				</tr>
				<tr>
					<td>市值+技术优势</td>
					<td colspan="3">市值200亿元以上<br>拥有自主研发、国际领先技术，科技创新能力较强，在同行业竞争中处于相对优势地位</td>
					<td>暂不适用</td>
				</tr>
				<tr>
					<td class="text-bold" rowspan="4">尚未在境外上市红筹</td>
					<td>一般情形</td>
					<td>市值+收入</td>
					<td colspan="3">预计市值不低于200亿元<br>最近一年营业收入不低于30亿元</td>
					<td>暂不适用</td>
				</tr>
				<tr>
					<td>高技术型</td>
					<td>技术优势+竞争优势</td>
					<td colspan="3">拥有自主研发、国际领先、能够引领国内重要领域发展的知识产权或专有技术，具备明显的技术优势的高新技术企业，研发人员占比超过30%，已取得与主营业务相关的发明专利100项以上，或者取得至少一项与主营业务相关的一类新药药品批件，或者拥有经有权主管部门认定具有国际领先和引领作用的核心技术<br>依靠科技创新与知识产权参与市场竞争，具有相对优势的竞争地位，主要产品市场占有率排名前三，最近三年营业收入复合增长率30%以上，最近一年经审计的主营业务收入不低于10亿元，且最近三年研发投入合计占主营业务收入合计的比例10%以上<br>对国家创新驱动发展战略有重要意义，且拥有较强发展潜力和市场前景的企业除外</td>
					<td>暂不适用</td>
				</tr>
				<tr>
					<td rowspan="2">高增长型</td>
					<td>市值</td>
					<td colspan="3">预计市值不低于100亿元</td>
					<td>暂不适用</td>
				</tr>
				<tr>
					<td>市值+收入</td>
					<td colspan="3">预计市值不低于50亿元<br>最近一年收入不低于5亿元</td>
					<td>暂不适用</td>
				</tr>
				<tr>
					<td colspan="2" rowspan="2" class="text-bold">设置差异表决权的企业</td>
					<td>市值+净利润</td>
					<td>预计市值不低于200亿元，且最近一年净利润为正</td>
					<td colspan="2">预计市值不低于100亿元</td>
					<td rowspan="2">设置表决权差异安排的财务条件与北交所IPO财务条件标准类型一致，市值+净利润+ROE标准和市值+营业收入+经营活动现金流标准下，设置表决权差异安排的财务条件对市值的要求略高于北交所IPO财务条件</td>
				</tr>
				<tr>
					<td>市值+净利润+营业收入</td>
					<td>预计市值不低于100亿元，且最近一年净利润为正，最近一年营业收入不低于10亿元</td>
					<td colspan="2">预计市值不低于50亿元<br>最近一年收入不低于5亿元</td>
				</tr>
				<tr>
					<td colspan="7">
						<p>注1：净利润以扣除非经常性损益前后的孰低者为准，后同</p>
						<p>注2：2020年6月12日发布的《创业板上市规则》通知中，明确了该上市标准一年内暂不实施的过渡期安排，2023年2月17日《创业板上市规则》修订后，该上市标准正式实施，属于先进制造、互联网、大数据、云计算、人工智能、生物医药等高新技术产业和战略性新兴产业的创新创业企业可适用该规定</p>
						<p>注3：尚未在境外上市的高增长型红筹是指营业收入快速增长，拥有自主研发、国际领先技术，同行业竞争中处于相对优势地位的尚未在境外上市红筹企业。其中，营业收入快速增长是指：</p>
						<p>（1）最近一年营业收入不低于人民币5亿元的，最近3年营业收入复合增长率10%以上；</p>
						<p>（2）最近一年营业收入低于人民币5亿元的，最近3年营业收入复合增长率20%以上；</p>
						<p>（3）受行业周期性波动等因素影响，行业整体处于下行周期的，发行人最近3年营业收入复合增长率高于同行业可比公司同期平均增长水平处于研发阶段的红筹企业和对国家创新驱动发展战略有重要意义的红筹企业除外。</p>
					</td>
				</tr>
			</table>
			
           </div>
       </div>
   </div>
</template>

<script>
    export default {
        name: "contrast",
        data(){
            return {
                currIndex:0,
            }
        },
        methods: {
            changeBox(e){
                console.log(e);
            }
        }
    }
</script>

<style scoped>
    #zhl_12 #box {
        width: 100%;
        /*height:2367px;*/
        margin-bottom: 50px;
        min-width: 1200px;
        width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
	
	
    #zhl_12 .jiaoliu, .aniu1 li {
        cursor: pointer;
    }

    #zhl_12 .nr {
        width: 1200px;
        /*height: 2500px;*/
        margin: 0 auto;
        position: relative;
    }

    .banner img {
        width: 100%;
        height: 340px;
        min-width: 1485px;
        width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );
    }
	
	.text-bold{
		font-weight: bold;
	}

    .duibi {
        width: 100%;
        height: 120px;
        text-align: center;
        color: #000000;
        font-size: 22px;
        line-height: 100px;
    }

    .duibi span {
        display: block;
        float: left;
        margin-left: 510px;
    }

    .jiaoliu {
        width: 205px;
        height: 45px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 45px;
        text-align: center;
        border-radius: 5px;
        margin-top: 25px;
        float: right;
        margin-right: 160px;
        font-weight: bold;
    }

    .jiaoliu a {
        color: #FFFFFF;
    }

    .zhl_btn {
        width: 46.4px;
        height: 835px;
    }

    .aniu {
        width: 46.4px;
        height: 852px;
        display: block;
        float: left;
        margin-top: 22px;
        padding-left: 5px;
    }

    .aniu ul li {
        display: block;
        width: 46.4px;
        height: 143px;
        padding: 10px 14px 0px 13px;
        background-color: #bfbfbf;
        margin-bottom: 23px;
        color: #FFFFFF;
        border-radius: 5px 0px 0px 5px;
        font-size: 24px;
    }

    #zhl_12 li.on {
        background-color: #34a8ff !important;
    }

    .aniu {
        position: absolute;
        top: 380px;
        font-weight: bold;
    }
	
	.table{
		width: 100%;
		color: #333333;
		border-top: 1px solid #999;
		border-left: 1px solid #999;
		border-spacing: 0;/*去掉单元格间隙*/
	}
	
	.table tr td{
		border-bottom: 1px solid #999;
		border-right: 1px solid #999;
		font-size: 14px;
		padding:10px 15px;
	}
	
	.table tr th{
		padding:15px 15px;
		color:#FFFFFF;
		background-color: #34a8ff;
		border-bottom:1px solid #999;
		border-right:1px solid #999;
	}
	
	.table tr td p{
		color: #666;
	}
	
    .cyb {
        width: 1160px;
        height: 1127px;
        display: block;
        float: right;
        left: 60px;
        margin: 0 auto;
        padding-left: 11px;
    }

    .none {
        display: none;
    }

    #zhl_12 div.on {
        opacity: 1;
    }

    .zou {
        width: 417.6px;
        height: 100%;
        float: left;
    }

    .zhong {
        width: 480px;
        height: 100%;
        float: left;
        margin-left: 18px;
    }

    .you {
        width: 212.8px;
        height: 100%;
        float: left;
        margin-left: 18px;
    }

    .tou {
        width: 212.8px;
        height: 70px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
        font-size: 25px;
    }

    .zoul {
        width: 236px;
        height: 60px;
        float: left;
        background-color: #d8edfe;
        text-align: center;
        line-height: 60px;
        color: #35a9fe;
        font-weight: bold;
    }

    .your {
        width: 179.2px;
        height: 60px;
        float: right;
        background-color: #d8edfe;
        text-align: center;
        line-height: 60px;
        color: #35a9fe;
        font-weight: bold;
    }

    .zoul2 {
        width: 236px;
        height: 146px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px;
        line-height: 35px;
    }

    .your2 {
        width: 179.2px;
        height: 146px;
        float: right;
        background-color: #d8edfe;
        text-align: center;
        line-height: 60px;
        color: #35a9fe;
        margin-top: 2px;
    }

    .zoul3 {
        width: 236px;
        height: 849px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 15px 0px 15px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .your3 {
        width: 179.2px;
        height: 849px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        text-align: justify;
        line-height: 30px;
        margin-top: 2px;
        padding: 60px 25px 0px 10px;
        text-indent: 2em;
    }

    .zzoul {
        width: 243.2px;
        height: 60px;
        background-color: #d8edfe;
        float: left;
        text-align: center;
        line-height: 60px;
        color: #35a7ff;
        font-weight: bold;
    }

    .zyour {
        width: 233.6px;
        height: 60px;
        background-color: #d8edfe;
        float: right;
        text-align: center;
        line-height: 60px;
        color: #35a7ff;
        font-weight: bold;
    }

    .zhong3z {
        width: 243.2px;
        height: 146px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px;
        line-height: 35px;
    }

    .zhong3r {
        width: 233.6px;
        height: 146px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px;
        line-height: 30px;
    }

    .zhong4z {
        width: 243.2px;
        height: 849px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 30px 0px 30px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .zhong4r {
        width: 233.6px;
        height: 849px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 30px 0px 30px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .you2 {
        width: 100%;
        height: 60px;
        background-color: #d8edfe;
        float: left;
        text-align: center;
        line-height: 60px;
        color: #35a7ff;
        font-weight: bold;
    }

    .you3 {
        width: 100%;
        height: 146px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px 15px 10px 15px;
        line-height: 30px;
    }

    .you4 {
        width: 100%;
        height: 849px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 20px 0px 20px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .nr2 {
        width: 1160px;
        height: 1150px;
        margin: 0 auto;
        margin-top: 1220px;
    }

    .title {
        width: 100%;
        height: 38px;
    }

    .title_z {
        font-size: 24px;
        color: #3ca9fb;
        font-weight: bold;
        line-height: 38px;
    }

    .title_y {
        display: block;
        float: right;
        line-height: 38px;
    }

    .title_y a {
        color: #000000;
    }

    .wenti {
        width: 100%;
        height: 640px;
        margin-top: 40px;
        font-size: 16px;
        padding-left: 75px;
        line-height: 30px;
    }

    .huifu {
        color: #3fa9ff;
        font-size: 14px;
        text-align: right;
        margin-right: 60px;
    }

    .huifu a {
        color: #3fa9ff;
    }

    .xian {
        width: 95%;
        height: 1px;
        background-color: #d2d2d2;
        margin-top: 45px;
    }

    .faxing {
        color: #3ca9fc;
        font-size: 18px;
    }

    .wenti2 {
        width: 100%;
        height: 400px;
        font-size: 16px;
        padding-left: 75px;
        margin-top: 143px;
        line-height: 30px;
    }

    .jdb {
        margin-top: 18px;
        margin-bottom: 50px;
    }

    .di {
        min-width: 1200px;
        width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );
    }

    .tou1 {
        width: 417.6px;
        height: 70px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
        font-size: 25px;
    }

    .tou2 {
        width: 480px;
        height: 70px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
        font-size: 25px;
    }

    .tubiao {
        width: 50px;
        height: 1200px;
        position: absolute;
        left: px;
        top: 1400px;
        font-size: 45px;
        color: #3ca9fc;
    }

    #zhl_12 {
        width: 100%;

    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }



</style>
