<template>
    <div>
        <TopBannerBox positions="9"></TopBannerBox>
        <contrast></contrast>
        <question></question>
    </div>
</template>

<script>
    // import contrast from "./contrast";
    import contrast from "./contrast-new";
    import question from "./question";
    import TopBannerBox from "../../../components/TopBannerBox";
    export default {
        name: "Consult",
        components: {
            contrast,question,TopBannerBox
        }
    }
</script>

<style scoped>

</style>
